import React, { Component } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signup } from '../actions/auth';

const Confirm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submit = (e) => {
        e.preventDefault();
        // console.log(props.values)
        dispatch(signup(props.values, navigate))
        props.nextStep();
    };

    const back = e => {
        e.preventDefault();
        props.prevStep();
    };

	const formatDate = (dob) => {
        const date1 = new Date(dob)
        const formattedDate = date1.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric"
        })

        return(formattedDate)
    }

    return (
        <div id='main' className="container">

            <div className="skew-container">

                <h2 className='glow-heading-light pt-0 text-center'>Confirm Details</h2>
                <div className="profile-pic">
                    <div className="text-lg-left text-center">
                        <div className="row align-items-start" style={{margin: 0}}>
                            <div className="col-lg-6 pr-lg-1">
                                <label htmlFor="Name" className='customized-label'>Full Name</label>
					                      <input className='customized-input' placeholder={`${props.values.firstName} ${props.values.lastName}`} disabled/>
                            </div>
                        </div>

                        <div className="row align-items-center" style={{margin: 0}}>
                            <div className="col-lg-6 pr-lg-1">
                                <label htmlFor="" className='customized-label'>Email</label>
					            <input className='customized-input' placeholder={props.values.email} disabled/>
                            </div>
                            <div className="col-lg-6 pl-lg-1">
                                <label htmlFor="" className='customized-label'>Phone</label>
					            <input className='customized-input' placeholder={props.values.phone} disabled/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="profile-pic my-3">
                    <button className='btn mr-1' onClick={back}>Edit</button>
                    <button className='btn ml-1' onClick={submit}>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default Confirm
