import { AUTH, LOGOUT, EMAIL_VERIFY, EMAIL_EXIST, START_LOADING, END_LOADING } from '../constants/actionTypes';

const authReducer = ( state = { isLoading: true, emailExists: null, authData: null}, action ) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };
        case AUTH:
            localStorage.setItem('profile', JSON.stringify({...action?.data}));
            return { ...state, authData: action?.data };
        case LOGOUT:
            localStorage.clear();
            return { ...state, authData: null };
        case EMAIL_VERIFY: 
            localStorage.setItem('profile', JSON.stringify({...action?.data}));
            return { ...state, authData: action?.data };
        case EMAIL_EXIST:
            return { ...state, emailExists: action.data }
        default:
            return state;
    }
}

export default authReducer;