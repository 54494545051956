import React from "react";
import Main from "./components/Main";
import { Helmet, HelmetProvider} from "react-helmet-async";

function App() {
	return (
	<HelmetProvider>
		<Main />
	</HelmetProvider>
	);
}

export default App;
