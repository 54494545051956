import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { emailExist } from '../actions/auth';

const errors = {
    firstNameError: "",
    lastNameError: "",
    phoneError: "",
    emailError: "",
    passError: '',
    confirmPassError: '',
    infoError: ''
}

const FormUserDetails = (props) => {
    
    const [error, setError] = useState(errors);
    const [showBox, setShowBox] = useState(false);
    const [mssg, setMssg] = useState('');
    const [ otpError, setOtpError ] = useState('');
	  const sysOTP = Math.floor(100000 + Math.random() * 900000);
    const [otp, setOtp] = useState(sysOTP);
    const [newOtp, setNewOtp] = useState();
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerification, setOtpVerification] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        props.setFormData({ ...props.formData, [e.target.name]: e.target.value });
    }

    const validate = () => {
        let firstNameError = "";
        let lastNameError = "";
        let phoneError = "";
        let emailError = "";
        let infoError = "";
        let passError = "";
        let confirmPassError = "";

        
        if (!props.formData.firstName) {;
            firstNameError = '*First Name can not be blank'
        }
        if (!props.formData.lastName) {
            lastNameError = '*Last Name can not be blank'
        }
        if (!props.formData.phone) {
            phoneError = '*Phone No. can not be blank'
        }
        if (!props.formData.email || !props.formData.email.includes('@')  ){
            emailError = "*Please Enter valid Email address."
        }
        if (!props.formData.password){
            passError = "*Please Enter password."
        }
        if (!props.formData.info){
            infoError = "*Please check the box."
        }
        if ( props.formData.confirmPassword  != props.formData.password){
            confirmPassError = "*Password doesn't match."
        }
        if (emailError || firstNameError || lastNameError || phoneError || passError || confirmPassError || infoError) {
            setError({emailError, firstNameError, lastNameError, phoneError, passError, confirmPassError, infoError});
            return false
        }

        return true
    }
    
    const next = (e) => {
        e.preventDefault();
        const isValid = validate()
        if(!otpVerification) {
            setOtpError('*Please verify your email address.')
        }
        if (isValid && otpVerification){
            props.nextStep();
        }
    }
    
    const exists = (e) => {
        e.preventDefault();
        let emailError = "";
        const valid = () => {
            if (!props.formData.email || !props.formData.email.includes('@')){
                emailError = "*Please Enter valid Email address."
            }
            if (emailError) {
                setError({emailError});
                return false
            }
            return true
        }
        const isValid = valid()
        if(isValid){
            dispatch(emailExist(props.formData.email, otp, setOtpSent, setNewOtp, setShowBox, setMssg));
        }
    }

    const checkOtp = (e) => {
        e.preventDefault();
        if(newOtp == otp) {
            setOtpVerification('✔ Email Verified')
        } else {
            setOtpVerification('Wrong OTP, please enter carefully.')
        }
    }
    
    return (
    <div id='main' className="container">
        <form>
              <div className="text-lg-left text-center">
                  <div className="row align-items-start" style={{margin: 0}}>
                      <div className="col-lg-6 pr-lg-1">
                          <label htmlFor="firstName">First Name</label>
                          <input className='customized-input' name='firstName' type="text" onChange={handleChange} defaultValue={props.formData.firstName} /> 
                          <div className="error">{errors.firstNameError}</div>
                      </div>
                      <div className="col-lg-6 pl-lg-1">
                          <label htmlFor="lastName">Last Name</label>
                          <input className='customized-input' name='lastName' type="text" onChange={handleChange} defaultValue={props.formData.lastName} /> 
                          <div className="error">{errors.lastNameError}</div>
                      </div>
                  </div>

                  <div className="row align-items-center" style={{margin: 0}}>
                      <div className="col-lg-6 pr-lg-1">
                          <label htmlFor="">Email <div className="red">*</div></label>
                          <input type="email" name='email' onChange={handleChange} defaultValue={props.formData.email}/> 
                          <div className='error'>{error.emailError}</div>
                          <div className='error'>{otpError}</div>
                      </div>

                      { !showBox ? 
                          <div className="col-lg-6 pl-lg-1">
                              <br />
                              <button className='btn' type='button' onClick={exists}>Send OTP</button>
                          </div> : 
                          <div className="col-lg-6 pl-lg-1">
                              <div className="row align-items-center" style={{margin: 0}}>
                                  <div className="col-8 pr-lg-1">
                                      <label></label>
                                      <input className='m-0' type="number" onChange={(e) => setNewOtp(e.target.value)} defaultValue={newOtp} />
                                  </div>
                                  <div className="col-4 pl-lg-1">
                                      <label></label>
                                      <button className='btn' type='button' disabled={!newOtp} onClick={checkOtp}>Verify</button>
                                  </div>
                              </div>
                          </div>
                      }
                      <div className='green pt-1 pb-4'>{ otpVerification }</div>
                  </div>

                  <div className="row align-items-start" style={{margin: 0}}>
                      <div className="col-lg-6 pl-lg-1">
                          <label htmlFor="">Phone No.</label>
                          <input className='customized-input' type="text" name='phone' onChange={handleChange} defaultValue={props.formData.phone}/> 
                          <div className='error'>{error.phoneError}</div>
                      </div>
                  </div>

                  <div className="row align-items-start" style={{margin: 0}}>
                      <div className="col-lg-6 pr-lg-1">
                          <label htmlFor="password" className="customized-label">Password</label>
                          <input type="password" name="password" className='customized-input' defaultValue={props.formData.password} onChange={handleChange} />
                          <div className='error'>{error.passError}</div>
                      </div>
                      <div className="col-lg-6 pl-lg-1">
                          <label htmlFor="confirmPassword" className="customized-label">Confirm Password</label>
                          <input type="password" name="confirmPassword" className='customized-input'  defaultValue={props.formData.confirmPassword} onChange={handleChange}/>
                          <div className='error'>{error.confirmPassError}</div>
                      </div>
                  </div>

          </div>

          <div className="row align-items-center my-3 px-2">
              <div className="col-lg-3 col-2 text-right pr-1">
                  <input type="checkbox" id="info" name="info" onChange={(e) => props.setFormData({...props.formData, info: e.target.checked}) } />
              </div>
              <div className="col-lg-9 col-10 pl-1">
                  <label htmlFor="info">
                  The above information provided is correct?</label>
              </div>
          </div>
          <div className='error'>{error.infoError}</div>

          {/* <h5>{mssg}</h5> */}
          <button className='btn-lg' onClick={next}>Continue</button>
                
        </form>
    </div>
    )
}

export default FormUserDetails
