import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { inventoryForm } from '../../../actions/auth';

const initialData = { productDesc: '', styleNo: '', sizeAvailableList: 0, detailDesc: '', designNo: '', uniqueProductId: '', category: '', price: '', discountPercent: '', maxDiscount: '', color: '', fabric: '', work: '', neckLine: '', sleeves: '', manufacturedby: ''  }

const Inventory = () => {

	const [inventoryData, setInventoryData] = useState(initialData);
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(inventoryForm(inventoryData))
	}

	const handleChange = (e) => {
		setInventoryData({ ...inventoryData, [e.target.name]: e.target.value });
	}

	return (
		<div>
			<h1>Inventory</h1>
			<form onSubmit={handleSubmit}>
				<input type="text" name='productDesc' placeholder='Product description' onChange={handleChange} />
				<input type="text" name='styleNo' placeholder='Style No.' onChange={handleChange} />
				<input type="text" name='sizeAvailableList' placeholder='Size available list (Comma separated numbers)' onChange={ (e) => setInventoryData({...inventoryData, sizeAvailableList: e.target.value.split(',').map(Number)})} />
				<input type="text" name='detailDesc' placeholder='Detail Desc (Comma separated string)' onChange={ (e) => setInventoryData({...inventoryData, detailDesc: e.target.value.split(',')})} />
				<input type="text" name='designNo' placeholder='Design no' onChange={handleChange} />
				<input type="text" name='uniqueProductId' placeholder='Unique product id' onChange={handleChange} />
				<input type="text" name='category' placeholder='Category' onChange={handleChange} />
				<input type="number" name='price' placeholder='Price (Number)' onChange={handleChange} />
				<input type="number" name='discountPercent' placeholder='Discount Percent (Number)' onChange={handleChange} />
				<input type="number" name='maxDiscount' placeholder='Max Discount (Number)' onChange={handleChange} />
				<input type="text" name='color' placeholder='Color' onChange={handleChange} />
				<input type="text" name='fabric' placeholder='Fabric' onChange={handleChange} />
				<input type="text" name='work' placeholder='Work' onChange={handleChange} />
				<input type="text" name='neckLine' placeholder='Neckline' onChange={handleChange} />
				<input type="text" name='sleeves' placeholder='Sleeves' onChange={handleChange} />
				<input type="text" name='manufacturedby' placeholder='Manufactured by' onChange={handleChange} />

				<button type='submit' className='btn'>Submit</button>
			</form>
		</div>
	)
}

export default Inventory