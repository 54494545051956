import React from 'react';

const Orders = () => {
  return (
    <div>
        <h1>Orders</h1>
    </div>
  )
}

export default Orders