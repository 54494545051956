import React, { Component } from 'react'
import  {Link} from 'react-router-dom'

export class Success extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        return (
            <div id='main' className="container">

                <div className="skew-container">
                    <h2 className='glow-heading-light pt-0 text-center'>Welcome to Safa Design</h2>
                    <p className='text-center'>To access the dashboard, login with your credentials.</p>
                    <div className='text-center my-3'><Link to='/login' className='btn'>Login</Link></div>
                </div>
            </div>
        )
    }
}

export default Success
