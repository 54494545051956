import axios from 'axios';

// const API = axios.create({ baseURL: 'http://localhost:8080' });
// const API = axios.create({ baseURL: 'http://localhost:5000' });
const API = axios.create({ baseURL: 'https://zany-cyan-lemming.cyclic.app' });

// API.interceptors.request.use((req) => {
//     if (localStorage.getItem('profile')) {
//         req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
//     };

//     return req;
// });


export const register = (formData) => API.post('/register', formData);
export const login = (formData) => API.get(`/login?password=${formData.password}&phone=${formData.phone}`, formData);
export const inventoryForm = (inventoryData) => API.post('/product/addProduct', inventoryData);




export const signUp = (formData) => API.post('/user/signup', formData);
export const signIn = (formData) => API.post('/user/signin', formData);
export const emailVerify = (email, otp) => API.post('/user/emailVerify', {email, otp});
export const emailExist = (email) => API.post('/user/emailExist', {email});
