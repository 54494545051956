import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import FormUserDetails from './FormUserDetails';
import Confirm from './Confirm';
import Success from './Success';
import logo from '../media/Safa_logo.png'

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', dob: null, profilePic: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png', phone: '', info: false, verified: false };

const Register = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const [steps, setStep] = useState(1)
    const [formData, setFormData] = useState(initialState)
    const navigate = useNavigate();

    useEffect(() => {
        if(user) {
            navigate('/')
        }
        document.title = "Register"
    }, [])


    
    const nextStep = () => {
        setStep(steps+1)
    }
    
    const prevStep = () => {
        setStep(steps - 1)
    }

    
    switch(steps) {
        case 1:
            return(
            <main>
                <div className='container'>
                    <section className='form-view'>
                        <div className="section-title text-center">
                            <img src={logo} alt="Safa Designs" width="180" />
                            <p className='pt-4'>Admin Registration</p>
                        </div>
                        <FormUserDetails nextStep={nextStep} formData={formData} setFormData={setFormData} />
                        <div className="dialog">
                            <p className='m-0'>Already an Admin?</p>
                            <Link to='/login'>Login</Link>
                        </div>
                    </section>
                </div>
            </main>
            )
        case 2:
            return(
            <main>
                <div className='container'>
                    <section className='form-view'>
                        <div className="section-title text-center">
                            <img src={logo} alt="Safa Designs" width="150" />
                            <p className='pt-4'>Admin Registration</p>
                        </div>
                    <Confirm nextStep={nextStep} prevStep={prevStep} values={formData} />
                    </section>
                </div>
            </main>
            )
        case 3:
            return(
            <main>
                <div className='container'>
                    <section className='form-view'>
                        <div className="section-title text-center">
                            <img src={logo} alt="Safa Designs" width="150" />
                            <p className='pt-4'>Admin Registration</p>
                        </div>
                    <Success />
                    </section>
                </div>
            </main>
            )
    }
}

export default Register;
