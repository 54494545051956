import { AUTH, START_LOADING, END_LOADING } from '../constants/actionTypes';
import * as api from '../api';

export const register = (formData) => async (dispatch) => {
    try {

        const { data } = api.register(formData);
        console.log(data)
        
    } catch (error) {
        console.log(error)
    }
}
export const login = (formData) => async (dispatch) => {
    try {
        console.log(formData)
        const { data } = api.login(formData);
        console.log(data)
        
    } catch (error) {
        console.log(error)
    }
}

export const inventoryForm = (invetoryData) => async (dispatch) => {
    try {
        const { data } = api.inventoryForm(invetoryData);

    } catch (error) {
        console.log(error)
    }
}





export const signup = (formData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signUp(formData);
        
        if(data.result) {
            dispatch({ type: AUTH, data });
            
        } else {
            // console.log(data)
        }
    } catch (error) {
        console.log(error);
    }
}

export const signin = (formData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);

        if(data.result) {

            dispatch({type: START_LOADING})

            dispatch({ type: AUTH, data });
            document.location.reload();

            dispatch({type: END_LOADING})

        } else {
            if(data.message === 'Email not found, please Sign up first.') alert(data.message);
            if(data.message === 'Password is wrong.') alert(data.message);
        }
    } catch (error) {
        console.log(error);
    }
}

export const emailExist  = (email, otp, setOtpSent, setNewOtp, setShowBox, setMssg) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const {data} = await api.emailExist({email});
        
        setMssg(data.message)
        if(data.status === 'ok') {
            dispatch(emailVerify(email, otp))
            setShowBox(true);
            setOtpSent(true);
        }
        
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error)
    }
}

const emailVerify = (email, otp) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.emailVerify(email, otp)

        // dispatch({type: EMAIL_VERIFY, data})

        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error)
    }
}