import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GoToTop from '../js/GoToTop';

const Home = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const navigate = useNavigate();

  useEffect(() => {
    if(!user) {
        navigate('/login')
    }
  }, [])

  return (
    <div>
        <h1>Home</h1>
        {user ? user.result.name : null}
        <GoToTop />
    </div>
  )
}

export default Home