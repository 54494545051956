import React, {useEffect} from 'react';
import { Link, NavLink, Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { Helmet, HelmetProvider} from "react-helmet-async";
import '../css/dashboard.css'
import GoToTop from '../js/GoToTop';

const DbNav = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const navigate = useNavigate();

    useEffect(() => {
        if(!user) {
            navigate('/login')
        }
    }, [])
    
    useEffect(() => {
        document.title = 'Safa Designs Admin';

        const dbNav = () => {
            const showNavbar = (toggleId, navId, bodyId, headerId) =>{
                const toggle = document.getElementById(toggleId),
                nav = document.getElementById(navId),
                bodypd = document.getElementById(bodyId),
                headerpd = document.getElementById(headerId)
                
                if(toggle && nav && bodypd && headerpd){
                    toggle.addEventListener('click', ()=>{
                    nav.classList.toggle('shows')
                    toggle.classList.toggle('bx-x')
                    bodypd.classList.toggle('body-pd')
                    headerpd.classList.toggle('body-pd')
                })
                }
            }
                
            showNavbar('header-toggle','nav-bar','body-pd','header')
                
            const linkColor = document.querySelectorAll('.nav_link')
            
            function colorLink(){
            if(linkColor){
            linkColor.forEach(l=> l.classList.remove('active'))
            this.classList.add('active')
            }
            }
            linkColor.forEach(l=> l.addEventListener('click', colorLink))
        };
        dbNav();
    }, [] )

    return (
    <div>
        <Helmet>
            <body id="body-pd" className='body-class' />
        </Helmet>

        <header className="header" id="header">
            <div className="header_toggle"> <i className='bx bx-menu' id="header-toggle"></i> </div>

            <div className="dropdown">
                <a className=" dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                data-bs-toggle="dropdown" aria-expanded="false">{user ? user.result.name : <>Account</>}</a>
            
                <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuLink">
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><button className="dropdown-item" onClick={() => {localStorage.clear(); navigate('/login')}}>Log Out</button></li>
                </ul>
            </div>

        </header>

        <div className="l-navbar" id="nav-bar">
            <nav className="nav">
                <div>
                    <a href="/" className="nav_logo"> <i className='bx bx-layer nav_logo-icon'></i> <span className="nav_logo-name">Safa Designs</span> </a>
                    <div className="nav_list">
                        <NavLink to='/' className={({ isActive }) => isActive ? 'nav_link active' : 'nav_link'}>
                            <i className='bx bx-grid-alt nav_icon'></i> <span className="nav_name">Dashboard</span>
                        </NavLink>
                        <NavLink to='/orders' className={({ isActive }) => isActive ? 'nav_link active' : 'nav_link'}>
                            <i className='bx bxs-package nav_icon'></i> <span className="nav_name">Orders</span>
                        </NavLink>
                        <NavLink to='/inventory' className={({ isActive }) => isActive ? 'nav_link active' : 'nav_link'}>
                            <i className='bx bx-closet nav_icon'></i> <span className="nav_name">Inventory</span>
                        </NavLink>
                        <NavLink to='/transactions' className={({ isActive }) => isActive ? 'nav_link active' : 'nav_link'}>
                            <i className='bx bx-receipt nav_icon'></i> <span className="nav_name">Transactions</span>
                        </NavLink>
                        <NavLink to='/users' className={({ isActive }) => isActive ? 'nav_link active' : 'nav_link'}>
                            <i className='bx bx-user nav_icon'></i> <span className="nav_name">Users</span>
                        </NavLink>
                        {/* <a href="#" className="nav_link"> <i className='bx bx-bookmark nav_icon'></i> <span className="nav_name">Bookmark</span> </a>
                        <a href="#" className="nav_link"> <i className='bx bx-bar-chart-alt-2 nav_icon'></i> <span className="nav_name">Stats</span> </a> */}
                        <a href="#" className="nav_link" onClick={() => {localStorage.clear(); navigate('/login')}}> <i className='bx bx-log-out nav_icon'></i> <span className="nav_name">Log Out</span> </a>
                    </div>
                </div>
                <a href="#" className="nav_link"> <i className='bx bx-code-alt nav_icon'></i> <span className="nav_name">BuiltByBrey</span> </a>
            </nav>
        </div>

        <div className="height-100 bg-light">
            <Outlet  />
        </div>
        <GoToTop />
    </div>
  )
}

export default DbNav