import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DbHome from './Dashboard/DbHome';
import DbNav from './Dashboard/DbNav';
import Inventory from './Dashboard/Inventory/Inventory';
import Orders from './Dashboard/Order/Orders';
import Transactions from './Dashboard/Transactions/Transactions';
import Users from './Dashboard/Users/Users';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import './css/main.css';
import './css/mobile.css';

const Main = () => {
    return (
    <div>
        <Router>
            <Routes>
                {/* <Route path='/' element={<Home />} /> */}
                <Route path='/register' element={<Register />} />
                <Route path='/login' element={<Login />} />
                <Route path='/' element={<DbNav />} >
                    <Route path='' element={<DbHome />} />
                    <Route path='orders' element={<Orders />} />
                    <Route path='transactions' element={<Transactions />} />
                    <Route path='inventory' element={<Inventory />} />
                    <Route path='users' element={<Users />} />
                </Route>

            </Routes>
        </Router>
    </div>
    )
}

export default Main