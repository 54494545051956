import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { login, signin } from '../actions/auth';
import logo from '../media/Safa_logo.png'
import GoToTop from './js/GoToTop';

const errors = { emailError: '', passError: ''}
const initialData = { email: '', password: '' }

const Login = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const [formData, setFormData] = useState(initialData);
  const [error, setError] = useState(errors);
  const [mssg, setMssg] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    if(user) {
        navigate('/')
    }
    document.title = "Login - Safa Designs Admin"
  }, [])

  const validate = () => {
		let emailError;
		let passError;

		if(!formData.email) {
			emailError = '*Please enter your email address.'
		}
		if(!formData.password) {
			passError = '*Please enter password.'
		}
		if(emailError || passError) {
			setError({emailError, passError})
			return false
		}

		return true
	}

  const handleSubmit = (e) => {
		e.preventDefault();
		const isValid = validate();
		if(validate) {
			dispatch(signin(formData, navigate, setMssg))
		}
	}

return (
<main>
    <div className='container'>
        <section className='form-view'>
            <div className="section-title text-center">
                <img src={logo} alt="Safa Designs by Kiran" width="180" />
                <p className='pt-4'>Admin Login</p>
            </div>

            <form onSubmit={handleSubmit} className="mx-lg-5 mx-3">

                <label htmlFor="email">Email</label>
                <input type="email" name='email' onChange={handleChange} placeholder='Email'/>
                <p className="error">{error.emailError}</p>

                <label htmlFor="password">Password</label>
                <input type="password" name='password' onChange={handleChange} placeholder='Password'/>
                <p className="error">{error.passError}</p>

                <p className="error">{mssg}</p>
                <button className='btn-lg' type='submit' >Sign In</button>

            </form>
            <div className="dialog">
                <p className='m-0'>New Admin?</p>
                <Link to='/register'>Create account</Link>
            </div>

        </section>
    </div>
    <GoToTop />
</main>
)
}

export default Login