import React from 'react';
import GoToTop from '../../js/GoToTop';

const Users = () => {
    return (
    <div>
        <h1>Users</h1>
        <GoToTop />
    </div>
    )
}

export default Users